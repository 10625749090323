import ViewListIcon from "@mui/icons-material/ViewList";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import { PATH_DASHBOARD } from "../../../routes/paths";
import Label from "../../../components/Label";
import Iconify from "../../../components/Iconify";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  menuItem: getIcon("ic_menu_item"),
};

const navConfig = [
  {
    subheader: "App",
    items: [
      {
        title: "Products",
        path: PATH_DASHBOARD.Products,
        icon: <ViewListIcon />,
      },
      {
        title: "New Product",
        path: PATH_DASHBOARD.NewProduct,
        icon: <PlaylistAddIcon />,
      },
    ],
  },
];

export default navConfig;
