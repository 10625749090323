import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import useAuth from '../hooks/useAuth';
import RouteConstants from '../constants/RouteConstants';
import moment from 'moment';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  const accessToken = localStorage.getItem('accessToken');
  const Token = accessToken && jwt_decode(accessToken);
  const { exp } = Token || {};
  const currentTimeInSeconds = moment().unix();
  const isTokenValid = currentTimeInSeconds < exp;
  
  if (isAuthenticated && isTokenValid) {
    return <>{children}</>;
  }

  return (
    <Navigate to={`${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`} />
  );
}
