import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import RouteConstants from '../constants/RouteConstants';

import jwt_decode from 'jwt-decode';
import moment from 'moment';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  const accessToken = localStorage.getItem('accessToken');
  const Token = accessToken && jwt_decode(accessToken);
  const { exp } = Token || {};
  const currentTimeInSeconds = moment().unix();
  const isTokenValid = currentTimeInSeconds < exp;

  if (isAuthenticated && isTokenValid) {
    return (
      <Navigate to={`/${RouteConstants.APP}/${RouteConstants.DASHBOARD}`} />
    );
  }

  return <>{children}</>;
}

