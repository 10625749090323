import RouteConstants from "../constants/RouteConstants";

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `/${root}/${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: RouteConstants.ROOT_AUTH,
  login: path(RouteConstants.ROOT_AUTH, RouteConstants.LOGIN),
  edit: path(RouteConstants.ROOT_AUTH, RouteConstants.EDIT),
  register: path(RouteConstants.ROOT_AUTH, RouteConstants.SIGNUP),
  loginUnprotected: path(RouteConstants.ROOT_AUTH, "login-unprotected"),
  registerUnprotected: path(RouteConstants.ROOT_AUTH, "register-unprotected"),
  verify: path(RouteConstants.ROOT_AUTH, "verify"),
  resetPassword: path(RouteConstants.ROOT_AUTH, "reset-password"),
  newPassword: path(RouteConstants.ROOT_AUTH, "new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  Dashboard: path(RouteConstants.APP, RouteConstants.DASHBOARD),
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
