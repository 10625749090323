import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    status:0
}

const slice = createSlice({
  name: 'bottomBar',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload
    },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { setStatus } = slice.actions;

