import APIService from '../APIService';

const API = process.env.REACT_APP_ROY_EXCHANGE;

const authServices = {
  sendSignUp(options) {
    options.roles = ['user'];
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/auth/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            source: 'vendor',
          },
          body: JSON.stringify(options),
        },
        (error, data) => {
          console.log(error, data);
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },

  sendSignIn(options) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/auth/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            source: 'vendor',
          },
          body: JSON.stringify(options),
        },
        (error, data) => {
          console.log(error, data);
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },

  sendOtpSignIn({ userId, otp }) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/auth/signin-otp-verify?id=${userId}&otp=${otp}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            source: 'vendor',
          },
        },
        (error, data) => {
          console.log(error, data);
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },

  sendReferralId( referralId ) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/api/v1/auth/referral`,
          method: 'POST',
          data: { referralId }
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },

  getBookingData(token) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/api/v1/admin/booking-data`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            source: 'vendor',
          },
          body: JSON.stringify(token),
        },
        (error, data) => {
          console.log(error, data);
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};

export default authServices;
