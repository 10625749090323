// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import BridgeConstants from './constants/BridgeConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  const navigationPage = useNavigate();
  const currentPath = window.location.pathname
  // console.log(currentPath);

  useEffect(() => {
    const unsubscribe = window.customEventEmitter.subscribe(
      BridgeConstants.BACK_BUTTON,
      (nativeData) => {
        console.log('nativeData--->', nativeData);
          navigationPage(-1);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
