// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT_AUTH: "auth",
  ON_BOARDING: "onboard",
  SIGNUP: "signup",
  LOGIN: "login",
  OTP_SIGNIN: "otp-verification",
  APP: "app",
  DASHBOARD: "dashboard",
  KYC_UPDATION: "kyc-updation",
  EDIT_PROFILE: "edit-profile",
  NAME_EDIT: "name-edit",
  EMAIL_EDIT: "email-edit",
  EDIT: 'edit',
  FIAT_SCREEN: 'fiat',
  CRYPTO_SCREEN: 'crypto',
  E_MONEY_SCREEN: 'emoney',
  WITHDRAW_SCREEN: 'withdraw',
  TRANSACTION_HISTORY: 'transaction-history',
  CALCULATOR: 'calculator',
  MODE_OF_TRANSACTION: 'mode-of-transaction',
  SELL_MODE_OF_TRANSACTION: 'sell-mode-of-transaction',
  ACCOUNT_TRANSFER: 'account-transfer',
  PICKUP_TRANSACTION: 'pickup',
  SELL_ACCOUNT_TRANSFER: 'sell-currency-account-transfer',
  SELL_PICKUP_TRANSACTION: 'sell-currency-pickup',
  SELL_DROP_TRANSACTION: 'sell-currency-drop-transaction',
  PAYMENT_CONFIRMATION_FAILED: 'payment-failed',
  ENTER_MONEY: 'enter-money',
  DROP_TRANSACTION: 'drop-transaction',
  DROP_CONFIRMATION: 'drop-confirmation',
  WALLET_BALANCE: 'wallet',
  ACCOUNT_TRANSFER_CONFIRMATION: 'account-transfer-confirmation',
  NOTIFICATIONS: 'notifications',
  PDF_PREVIEW: 'pdf-preview',
  REFERRAL: 'referral',
  BANK_ACCOUNT: 'bank-account',
};
