export default {
  NATIVE_DATA: 'NATIVE_DATA',
  BRIDGE_TIMEOUT_ERR: 'Timeout! Unable to get Native Data',
  DOWNLOAD_ATTACHMENTS: 'DOWNLOAD_ATTACHMENTS',
  PRINT: 'PRINT',
  LOCATION: 'LOCATION',
  SHARE_PDF: 'SHARE_PDF',
  BACK_BUTTON: 'BACK_BUTTON',
  CLOSE_APP: 'CLOSE_APP',
  ENABLE_LOCATION: 'ENABLE_LOCATION',
  SHARE_REFERRAL: 'SHARE_REFERRAL',
};
