import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { get } from 'lodash';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

import ObjectStorage from '../modules/ObjectStorage';
import AdminAuthService from '../services/API/AdminAuthService';
import authServices from '../services/API/AuthServices';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  admin: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, admin } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      admin,
    };
  },
  LOGIN: (state, action) => {
    const { admin } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      admin,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    admin: null,
  }),
  REGISTER: (state, action) => {
    const { admin } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      admin,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const { user } = localStorage.getItem('UserData');

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const loginOtp = async ({ userId, otp }) => {
    const response = await authServices.sendOtpSignIn({ userId, otp });

    const token = response.data.accessToken;
    const refreshToken = response.data.refreshToken;
    const user = response.data.user;

    ObjectStorage.setItem('accessToken', { token });
    ObjectStorage.setItem('UserData', user);
    ObjectStorage.setItem('refreshToken', {refreshToken});

    dispatch({
      type: 'LOGIN',
      isAuthenticated: true,
      payload: { user },
    });
  };

  const register = async (props) => {
    await authServices.sendSignUp(props);
   
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        loginOtp,
        logout,
        register,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
