import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RouteConstants from '../constants/RouteConstants';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen isDashboard={pathname.includes('/dashboard')} />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <Navigate
              to={`/${RouteConstants.ROOT_AUTH}/${RouteConstants.ON_BOARDING}`}
              replace
            />
          ),
          index: true,
        },
      ],
    },

    {
      path: RouteConstants.ROOT_AUTH,
      children: [
        {
          path: RouteConstants.ON_BOARDING,
          element: (
            <GuestGuard>
              <OnBoarding />
            </GuestGuard>
          ),
        },
        {
          path: RouteConstants.LOGIN,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: RouteConstants.OTP_SIGNIN,
          element: (
            <GuestGuard>
              <OTPVerification />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:token', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.DASHBOARD,
              element: <Dashboard />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.KYC_UPDATION,
              element: <KycUpdation />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.EDIT_PROFILE,
              element: <EditProfile />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.NAME_EDIT,
              element: <NameEdit />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.EMAIL_EDIT,
              element: <EmailEdit />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.FIAT_SCREEN,
              element: <FiatScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.ENTER_MONEY,
              element: <EnterMoney />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.PAYMENT_CONFIRMATION_FAILED,
              element: <PaymentConfirmationFailed />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.DROP_CONFIRMATION,
              element: <DropConfirmation />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.CRYPTO_SCREEN,
              element: <CryptoScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.E_MONEY_SCREEN,
              element: <EMoneyScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.WITHDRAW_SCREEN,
              element: <WithdrawScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.TRANSACTION_HISTORY,
              element: <TransactionHistory />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.CALCULATOR,
              element: <Calculator />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.MODE_OF_TRANSACTION,
              element: <ModeOfTransaction />,
            },
          ],
        },

        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.SELL_MODE_OF_TRANSACTION,
              element: <SellModeOfTransaction />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.ACCOUNT_TRANSFER,
              element: <AccountTransfer />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.PICKUP_TRANSACTION,
              element: <PickUpTransaction />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.DROP_TRANSACTION,
              element: <DropTransaction />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.SELL_ACCOUNT_TRANSFER,
              element: <SellAccountTransfer />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.SELL_PICKUP_TRANSACTION,
              element: <SellPickUpTransaction />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.SELL_DROP_TRANSACTION,
              element: <SellDropTransaction />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.NOTIFICATIONS,
              element: <Notifications />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.PDF_PREVIEW,
              element: <PDFPreview />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.REFERRAL,
              element: <RefferalScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.BANK_ACCOUNT,
              element: <BankAccountScreen />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.ACCOUNT_TRANSFER_CONFIRMATION,
              element: <AccountTransferConfirmation />,
            },
          ],
        },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.WALLET_BALANCE,
              element: <WalletBalance />,
            },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const OnBoarding = Loadable(lazy(() => import('../pages/auth/OnBoardScreen')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const OTPVerification = Loadable(lazy(() => import('../pages/auth/OTPVerification')));

const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));

const Dashboard = Loadable(lazy(() => import('../pages/app/Dashboard')));
const WalletBalance = Loadable(
  lazy(() => import('../pages/app/WalletTotalBalance'))
);

const FiatScreen = Loadable(lazy(() => import('../pages/app/FiatScreen')));
const Notifications = Loadable(lazy(() => import('../pages/app/Notifications')));
const PDFPreview = Loadable(lazy(() => import('../pages/app/PDFPreview')));
const AccountTransferConfirmation = Loadable(lazy(() => import('../pages/app/AccountTransferConfirmation')));
const PickUpTransaction = Loadable(lazy(() => import('../pages/app/PickUpTransaction')));
const KycUpdation = Loadable(lazy(() => import('../pages/app/KycUpdation')));
const EditProfile = Loadable(lazy(() => import('../pages/app/EditProfile')));
const NameEdit = Loadable(lazy(() => import('../pages/app/NameEdit')));
const EmailEdit = Loadable(lazy(() => import('../pages/app/EmailEdit')));
const PaymentConfirmationFailed = Loadable(lazy(() => import('../pages/app/PickupConfirmation')));
const DropConfirmation = Loadable(lazy(() => import('../pages/app/DropConfirmation')));
const CryptoScreen = Loadable(lazy(() => import('../pages/app/CryptoScreen')));
const RefferalScreen = Loadable(lazy(() => import('../pages/app/RefferalScreen')));
const EMoneyScreen = Loadable(lazy(() => import('../pages/app/EMoneyScreen')));
const WithdrawScreen = Loadable(lazy(() => import('../pages/app/WithdrawScreen')));
const TransactionHistory = Loadable( lazy(() => import('../pages/app/TransactionHistory')));
const Calculator = Loadable(lazy(() => import('../pages/app/Calculator')));
const EnterMoney = Loadable(lazy(() => import('../pages/app/EnterMoney')));
const ModeOfTransaction = Loadable(lazy(() => import('../pages/app/ModeOfTransaction')));
const BankAccountScreen = Loadable(lazy(() => import('../pages/app/BankAccountScreen')));
const SellModeOfTransaction = Loadable( lazy(() => import('../pages/app/SellModeOfTransaction')));
const DropTransaction = Loadable( lazy(() => import('../pages/app/DropTransaction')));
const AccountTransfer = Loadable( lazy(() => import('../pages/app/AccountTransfer')));
const SellDropTransaction = Loadable( lazy(() => import('../pages/app/Currency-sell-transactions/SellDropTransactions')));
const SellAccountTransfer = Loadable(lazy(() => import('../pages/app/Currency-sell-transactions/SellAccountTransfer')));
const SellPickUpTransaction = Loadable(lazy(() => import('../pages/app/Currency-sell-transactions/SellPickupTransaction')));